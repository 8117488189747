export const onto_example = {
  source: [
    {
      id: "http://purl.obolibrary.org/obo/ENVO_00000191_source",
      label: "solid astronomical body part",
      children: [
        {
          id: "http://purl.obolibrary.org/obo/ENVO_00002000_source",
          label: "slope",
          children: [
            {
              id: "http://purl.obolibrary.org/obo/ENVO_00000141_source",
              label: "bank",
              children: [
                {
                  id: "http://purl.obolibrary.org/obo/ENVO_00000142_source",
                  label: "stream bank",
                  children: [
                    {
                      id: "http://purl.obolibrary.org/obo/ENVO_00000143_source",
                      label: "river bank",
                      children: [],
                      position: "source",
                    },
                  ],
                  position: "source",
                },
              ],
              position: "source",
            },
          ],
          position: "source",
        },
        {
          id: "http://purl.obolibrary.org/obo/ENVO_00000309_source",
          label: "depression",
          children: [
            {
              id: "http://purl.obolibrary.org/obo/ENVO_00000395_source",
              label: "channel of a watercourse",
              children: [],
              position: "source",
            },
          ],
          position: "source",
        },
      ],
      position: "source",
    },
  ],
  target: [
    {
      id: "http://sweetontology.net/realmLandCoastal/Coastal_target",
      label: "coastal@en",
      children: [],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/CoastalRegion_target",
      label: "coastal region@en",
      children: [
        {
          id: "http://sweetontology.net/realmLandCoastal/Spit_target",
          label: "spit@en",
          children: [
            {
              id: "http://sweetontology.net/realmLandCoastal/Bar_target",
              label: "bar@en",
              children: [
                {
                  id: "http://sweetontology.net/realmLandCoastal/Tombolo_target",
                  label: "tombolo@en",
                  children: [],
                  position: "target",
                },
              ],
              position: "target",
            },
          ],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/CoastalMargin_target",
          label: "coastal margin@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/CoastalPlain_target",
          label: "coastal plain@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Cove_target",
          label: "cove@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Harbor_target",
          label: "harbor@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/IntertidalZone_target",
          label: "intertidal zone@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Isthmus_target",
          label: "isthmus@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Riparian_target",
          label: "riparian@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Shore_target",
          label: "shore@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/WaveCutPlatform_target",
          label: "wave cut platform@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Supratidal_target",
          label: "supratidal@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Terracette_target",
          label: "terracette@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Tidal_target",
          label: "tidal@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/Updrift_target",
          label: "updrift@en",
          children: [],
          position: "target",
        },
        {
          id: "http://sweetontology.net/realmLandCoastal/WaveAttackZone_target",
          label: "wave attack zone@en",
          children: [],
          position: "target",
        },
      ],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/DeltaBar_target",
      label: "delta bar@en",
      children: [],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/Intertidal_target",
      label: "intertidal@en",
      children: [],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/Littoral_target",
      label: "littoral@en",
      children: [],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/LittoralZone_target",
      label: "littoral zone@en",
      children: [],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/RiparianZone_target",
      label: "riparian zone@en",
      children: [],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/ShorePlatform_target",
      label: "shore platform@en",
      children: [],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/Shoreline_target",
      label: "shoreline@en",
      children: [],
      position: "target",
    },
    {
      id: "http://sweetontology.net/realmLandCoastal/TidePool_target",
      label: "tide pool@en",
      children: [],
      position: "target",
    },
  ],
};
