export const mapping_example = {
  one: {
    "http://purl.obolibrary.org/obo/ENVO_00000191": {
      "http://sweetontology.net/realmLandCoastal/Littoral": {
        sourceTitle: "solid astronomical body part",
        targetTitle: "littoral@en",
        relation: "skos:closeMatch",
        comment: "",
      },
    },
  },
  three: {
    "http://purl.obolibrary.org/obo/ENVO_00000191": {
      "http://sweetontology.net/realmLandCoastal/Littoral": {
        sourceTitle: "solid astronomical body part",
        targetTitle: "littoral@en",
        relation: "skos:closeMatch",
        comment: "",
      },
      "http://sweetontology.net/realmLandCoastal/LittoralZone": {
        sourceTitle: "solid astronomical body part",
        targetTitle: "littoral zone@en",
        relation: "skos:closeMatch",
        comment: "",
      },
    },
    "http://purl.obolibrary.org/obo/ENVO_00000143": {
      "http://sweetontology.net/realmLandCoastal/RiparianZone": {
        sourceTitle: "river bank",
        targetTitle: "riparian zone@en",
        relation: "skos:closeMatch",
        comment: "",
      },
    },
  },
  five: {
    "http://purl.obolibrary.org/obo/ENVO_00000191": {
      "http://sweetontology.net/realmLandCoastal/Littoral": {
        sourceTitle: "solid astronomical body part",
        targetTitle: "littoral@en",
        relation: "skos:closeMatch",
        comment: "",
      },
      "http://sweetontology.net/realmLandCoastal/LittoralZone": {
        sourceTitle: "solid astronomical body part",
        targetTitle: "littoral zone@en",
        relation: "skos:closeMatch",
        comment: "",
      },
    },
    "http://purl.obolibrary.org/obo/ENVO_00000143": {
      "http://sweetontology.net/realmLandCoastal/RiparianZone": {
        sourceTitle: "river bank",
        targetTitle: "riparian zone@en",
        relation: "skos:closeMatch",
        comment: "",
      },
    },
    "http://purl.obolibrary.org/obo/ENVO_00000395": {
      "http://sweetontology.net/realmLandCoastal/DeltaBar": {
        sourceTitle: "channel of a watercourse",
        targetTitle: "delta bar@en",
        relation: "skos:closeMatch",
        comment: "",
      },
      "http://sweetontology.net/realmLandCoastal/Intertidal": {
        sourceTitle: "channel of a watercourse",
        targetTitle: "intertidal@en",
        relation: "skos:closeMatch",
        comment: "",
      },
    },
  },
};
